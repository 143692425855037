import React from 'reactstrap'

const Footer = () => {
  return (
    <div id='foot' className="navbar-light bg-light text-center">
    <footer>
      <p>Copyright 2022 Claude Mokbel</p>
    </footer>
    </div>
  )
}

export default Footer;